<template>
  <div class="setting-item s-add-resource-or-role-config">
    <esmp-radio-group
      v-model="localSetting.value"
      :items="addMoreTypesList"
    />
  </div>
</template>

<script>
export default {
  name: 'SAddResourceOrRoleConfig',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addMoreTypesList: [
        {
          label: 'Ничего не добавлять',
          value: 1,
        },
        {
          label: 'Добавить ресурс',
          value: 2,
        },
        {
          label: 'Добавить роль',
          value: 3,
        },
      ],
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
